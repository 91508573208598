<template>
  <div class="about">
    <div>
      <img src="/images/banner.jpg" style="width: 100%; margin-bottom: 30px" />
    </div>
    <div style="width: 98%; margin: 0 1%" v-if="$width > 767">
      <div class="pro_qishuh1">{{ detail.pName }}</div>
      <div class="nav">
        <span :class="active == 0 ? 'active' : ''" @click="changeActive(0)">
          基金情况
        </span>
        <span :class="active == 1 ? 'active' : ''" @click="changeActive(1)">
          基金月报
        </span>
        <span :class="active == 2 ? 'active' : ''" @click="changeActive(2)">
          基金季报
        </span>
        <span :class="active == 3 ? 'active' : ''" @click="changeActive(3)">
          基金年报
        </span>
        <span :class="active == 4 ? 'active' : ''" @click="changeActive(4)">
          清算报告
        </span>
        <span :class="active == 5 ? 'active' : ''" @click="changeActive(5)">
          公告
        </span>
      </div>

      <!-- <audio :src="src" autoplay controls="controls"></audio> -->

      <!-- 基金情况 -->
      <el-row v-if="active == 0">
        <el-col :span="12">
          <div class="info"><b>基金名称：</b>{{ detail.pName }}</div>
        </el-col>
        <el-col :span="12">
          <div class="info"><b>基金编号：</b>{{ detail.jjbh }}</div>
        </el-col>
        <el-col :span="12">
          <div class="info"><b>基金类型：</b>{{ detail.jjlx }}</div>
        </el-col>
        <el-col :span="12">
          <div class="info"><b>基金币种：</b>{{ detail.jjbz }}</div>
        </el-col>
        <el-col :span="12">
          <div class="info"><b>管理人名称：</b>{{ detail.jjbz }}</div>
        </el-col>
        <el-col :span="12">
          <div class="info"><b>管理人编号：</b>{{ detail.glrbh }}</div>
        </el-col>
        <el-col :span="12">
          <div class="info"><b>管理类型：</b>{{ detail.gllx }}</div>
        </el-col>
        <el-col :span="12">
          <div class="info"><b>托管人名称：</b>{{ detail.gllx }}</div>
        </el-col>
        <el-col :span="12">
          <div class="info">
            <b>备案查询：</b><a :href="detail.yzdz">{{ detail.yzdz }}</a>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="info"><b>运作状态：</b>{{ detail.yzzt }}</div>
        </el-col>
        <el-col :span="24">
          <div class="info"><b>主要投资领域：</b>{{ detail.tzly }}</div>
        </el-col>
      </el-row>

      <!-- 基金月报 -->
      <div v-if="active == 1">
        <el-row class="th">
          <el-col :span="4">估值日期</el-col>
          <el-col :span="4">份额净值</el-col>
          <el-col :span="4">资产净值</el-col>
          <el-col :span="4">基金规模</el-col>
          <el-col :span="4">收益率</el-col>
          <el-col :span="4">月度报表</el-col>
        </el-row>

        <div class="nocontent" v-if="list.length == 0">暂无内容</div>
        <div v-if="list.length > 0">
          <el-row
            class="tr"
            :class="i % 2 == 1 ? 'tr2' : ''"
            v-for="(l, i) of list"
            :key="'jjyb' + i"
          >
            <el-col :span="4">{{ l.gzrq ? l.gzrq : "-" }}</el-col>
            <el-col :span="4">{{ l.fsjz ? l.fsjz : "-" }}</el-col>
            <el-col :span="4">{{ l.jjjz ? l.jjjz : "-" }}</el-col>
            <el-col :span="4">{{ l.zjgm ? l.zjgm : "-" }}</el-col>
            <el-col :span="4">{{ l.jsy ? l.jsy : "-" }}</el-col>
            <el-col :span="4"><a class="btn" :href="l.pdf">查看月报</a></el-col>
          </el-row>

          <div style="text-align: center; padding: 30px">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="pages[2]"
              @current-change="changePage"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- 基金季报 -->
      <div v-if="active == 2">
        <el-row class="th">
          <el-col :span="4">估值日期</el-col>
          <el-col :span="4">份额净值</el-col>
          <el-col :span="4">资产净值</el-col>
          <el-col :span="4">基金规模</el-col>
          <el-col :span="4">收益率</el-col>
          <el-col :span="4">月度报表</el-col>
        </el-row>

        <div class="nocontent" v-if="list.length == 0">暂无内容</div>
        <div v-if="list.length > 0">
          <el-row
            class="tr"
            :class="i % 2 == 1 ? 'tr2' : ''"
            v-for="(l, i) of list"
            :key="'jjyb' + i"
          >
            <el-col :span="4">{{ l.gzrq ? l.gzrq : "-" }}</el-col>
            <el-col :span="4">{{ l.fsjz ? l.fsjz : "-" }}</el-col>
            <el-col :span="4">{{ l.jjjz ? l.jjjz : "-" }}</el-col>
            <el-col :span="4">{{ l.zjgm ? l.zjgm : "-" }}</el-col>
            <el-col :span="4">{{ l.jsy ? l.jsy : "-" }}</el-col>
            <el-col :span="4"><a class="btn" :href="l.pdf">查看季报</a></el-col>
          </el-row>

          <div style="text-align: center; padding: 30px">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="pages[2]"
              @current-change="changePage"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- 基金年报 -->
      <div v-if="active == 3">
        <el-row class="th">
          <el-col :span="4">估值日期</el-col>
          <el-col :span="4">份额净值</el-col>
          <el-col :span="4">资产净值</el-col>
          <el-col :span="4">基金规模</el-col>
          <el-col :span="4">收益率</el-col>
          <el-col :span="4">月度报表</el-col>
        </el-row>
        <div class="nocontent" v-if="list.length == 0">暂无内容</div>
        <div v-if="list.length > 0">
          <el-row
            class="tr"
            :class="i % 2 == 1 ? 'tr2' : ''"
            v-for="(l, i) of list"
            :key="'jjyb' + i"
          >
            <el-col :span="4">{{ l.gzrq ? l.gzrq : "-" }}</el-col>
            <el-col :span="4">{{ l.fsjz ? l.fsjz : "-" }}</el-col>
            <el-col :span="4">{{ l.jjjz ? l.jjjz : "-" }}</el-col>
            <el-col :span="4">{{ l.zjgm ? l.zjgm : "-" }}</el-col>
            <el-col :span="4">{{ l.jsy ? l.jsy : "-" }}</el-col>
            <el-col :span="4"><a class="btn" :href="l.pdf">查看年报</a></el-col>
          </el-row>

          <div style="text-align: center; padding: 30px">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="pages[2]"
              @current-change="changePage"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- 清算报告 -->
      <div v-if="active == 4">
        <el-row class="th">
          <el-col :span="4">估值日期</el-col>
          <el-col :span="4">份额净值</el-col>
          <el-col :span="4">资产净值</el-col>
          <el-col :span="4">基金规模</el-col>
          <el-col :span="4">收益率</el-col>
          <el-col :span="4">清算报告</el-col>
        </el-row>

        <div class="nocontent" v-if="list.length == 0">暂无内容</div>
        <div v-if="list.length > 0">
          <el-row
            class="tr"
            :class="i % 2 == 1 ? 'tr2' : ''"
            v-for="(l, i) of list"
            :key="'jjyb' + i"
          >
            <el-col :span="4">{{ l.gzrq ? l.gzrq : "-" }}</el-col>
            <el-col :span="4">{{ l.fsjz ? l.fsjz : "-" }}</el-col>
            <el-col :span="4">{{ l.jjjz ? l.jjjz : "-" }}</el-col>
            <el-col :span="4">{{ l.zjgm ? l.zjgm : "-" }}</el-col>
            <el-col :span="4">{{ l.jsy ? l.jsy : "-" }}</el-col>
            <el-col :span="4"><a class="btn" :href="l.pdf">查看报告</a></el-col>
          </el-row>

          <div style="text-align: center; padding: 30px">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="pages[2]"
              @current-change="changePage"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- 公告 -->
      <div v-if="active == 5">
        <el-row class="th">
          <el-col :span="4">估值日期</el-col>
          <el-col :span="4">份额净值</el-col>
          <el-col :span="4">资产净值</el-col>
          <el-col :span="4">基金规模</el-col>
          <el-col :span="4">收益率</el-col>
          <el-col :span="4">清算报告</el-col>
        </el-row>

        <div class="nocontent" v-if="list.length == 0">暂无内容</div>
        <div v-if="list.length > 0">
          <el-row
            class="tr"
            :class="i % 2 == 1 ? 'tr2' : ''"
            v-for="(l, i) of list"
            :key="'jjyb' + i"
          >
            <el-col :span="4">{{ l.gzrq ? l.gzrq : "-" }}</el-col>
            <el-col :span="4">{{ l.fsjz ? l.fsjz : "-" }}</el-col>
            <el-col :span="4">{{ l.jjjz ? l.jjjz : "-" }}</el-col>
            <el-col :span="4">{{ l.zjgm ? l.zjgm : "-" }}</el-col>
            <el-col :span="4">{{ l.jsy ? l.jsy : "-" }}</el-col>
            <el-col :span="4"><a class="btn" :href="l.pdf">查看公告</a></el-col>
          </el-row>

          <div style="text-align: center; padding: 30px">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="pages[2]"
              @current-change="changePage"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <div style="width: 98%; margin: 0 1%" v-if="$width < 767">
      <div class="pro_qishuh1">{{ detail.pName }}</div>
      <el-row class="th">
        <el-col :span="12"
          ><div
            class="info"
            :class="active == 0 ? 'active' : ''"
            @click="changeActive(0)"
          >
            基金情况
          </div></el-col
        >
        <el-col :span="12"
          ><div
            class="info"
            :class="active == 1 ? 'active' : ''"
            @click="changeActive(1)"
          >
            基金月报
          </div></el-col
        >
      </el-row>
      <el-row class="th">
        <el-col :span="12"
          ><div
            class="info"
            :class="active == 2 ? 'active' : ''"
            @click="changeActive(2)"
          >
            基金季报
          </div></el-col
        >
        <el-col :span="12"
          ><div
            class="info"
            :class="active == 3 ? 'active' : ''"
            @click="changeActive(3)"
          >
            基金年报
          </div></el-col
        >
      </el-row>
      <el-row class="th">
        <el-col :span="12"
          ><div
            class="info"
            :class="active == 4 ? 'active' : ''"
            @click="changeActive(4)"
          >
            清算报告
          </div></el-col
        >
        <el-col :span="12"
          ><div
            class="info"
            :class="active == 5 ? 'active' : ''"
            @click="changeActive(5)"
          >
            公告
          </div></el-col
        >
      </el-row>

      <!-- 基金情况 -->
      <el-row v-if="active == 0" class="info2">
        <el-col :span="24">
          <div class="info"><b>基金名称：</b>{{ detail.pName }}</div>
        </el-col>
        <el-col :span="24">
          <div class="info"><b>基金编号：</b>{{ detail.jjbh }}</div>
        </el-col>
        <el-col :span="24">
          <div class="info"><b>基金类型：</b>{{ detail.jjlx }}</div>
        </el-col>
        <el-col :span="24">
          <div class="info"><b>基金币种：</b>{{ detail.jjbz }}</div>
        </el-col>
        <el-col :span="24">
          <div class="info"><b>管理人名称：</b>{{ detail.jjbz }}</div>
        </el-col>
        <el-col :span="24">
          <div class="info"><b>管理人编号：</b>{{ detail.glrbh }}</div>
        </el-col>
        <el-col :span="24">
          <div class="info"><b>管理类型：</b>{{ detail.gllx }}</div>
        </el-col>
        <el-col :span="24">
          <div class="info"><b>托管人名称：</b>{{ detail.gllx }}</div>
        </el-col>
        <el-col :span="24">
          <div class="info">
            <b>备案查询：</b><a :href="detail.yzdz">{{ detail.yzdz }}</a>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="info"><b>运作状态：</b>{{ detail.yzzt }}</div>
        </el-col>
        <el-col :span="24">
          <div class="info"><b>主要投资领域：</b>{{ detail.tzly }}</div>
        </el-col>
      </el-row>

      <!-- 基金月报 -->
      <div v-if="active == 1">
        <el-row class="th">
          <el-col :span="16">估值日期</el-col>
          <el-col :span="8">月度报表</el-col>
        </el-row>

        <div class="nocontent" v-if="list.length == 0">暂无内容</div>
        <div v-if="list.length > 0">
          <el-row
            class="tr"
            :class="i % 2 == 1 ? 'tr2' : ''"
            v-for="(l, i) of list"
            :key="'jjyb' + i"
          >
            <el-col :span="16">{{ l.gzrq ? l.gzrq : "-" }}</el-col>
            <el-col :span="8"><a class="btn" :href="l.pdf">查看月报</a></el-col>
          </el-row>

          <div style="text-align: center; padding: 30px">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="pages[2]"
              @current-change="changePage"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- 基金季报 -->
      <div v-if="active == 2">
        <el-row class="th">
          <el-col :span="16">估值日期</el-col>
          <el-col :span="8">季度报表</el-col>
        </el-row>

        <div class="nocontent" v-if="list.length == 0">暂无内容</div>
        <div v-if="list.length > 0">
          <el-row
            class="tr"
            :class="i % 2 == 1 ? 'tr2' : ''"
            v-for="(l, i) of list"
            :key="'jjyb' + i"
          >
            <el-col :span="16">{{ l.gzrq ? l.gzrq : "-" }}</el-col>
            <el-col :span="8"><a class="btn" :href="l.pdf">查看季报</a></el-col>
          </el-row>

          <div style="text-align: center; padding: 30px">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="pages[2]"
              @current-change="changePage"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- 基金年报 -->
      <div v-if="active == 3">
        <el-row class="th">
          <el-col :span="16">估值日期</el-col>
          <el-col :span="8">年度报表</el-col>
        </el-row>

        <div class="nocontent" v-if="list.length == 0">暂无内容</div>
        <div v-if="list.length > 0">
          <el-row
            class="tr"
            :class="i % 2 == 1 ? 'tr2' : ''"
            v-for="(l, i) of list"
            :key="'jjyb' + i"
          >
            <el-col :span="16">{{ l.gzrq ? l.gzrq : "-" }}</el-col>
            <el-col :span="8"><a class="btn" :href="l.pdf">查看年报</a></el-col>
          </el-row>

          <div style="text-align: center; padding: 30px">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="pages[2]"
              @current-change="changePage"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- 清算报告 -->
      <div v-if="active == 4">
        <el-row class="th">
          <el-col :span="16">估值日期</el-col>
          <el-col :span="8">清算报告</el-col>
        </el-row>

        <div class="nocontent" v-if="list.length == 0">暂无内容</div>
        <div v-if="list.length > 0">
          <el-row
            class="tr"
            :class="i % 2 == 1 ? 'tr2' : ''"
            v-for="(l, i) of list"
            :key="'jjyb' + i"
          >
            <el-col :span="16">{{ l.gzrq ? l.gzrq : "-" }}</el-col>
            <el-col :span="8"><a class="btn" :href="l.pdf">查看报表</a></el-col>
          </el-row>

          <div style="text-align: center; padding: 30px">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="pages[2]"
              @current-change="changePage"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- 公告 -->
      <div v-if="active == 5">
        <el-row class="th">
          <el-col :span="16">估值日期</el-col>
          <el-col :span="8">查看报表</el-col>
        </el-row>

        <div class="nocontent" v-if="list.length == 0">暂无内容</div>
        <div v-if="list.length > 0">
          <el-row
            class="tr"
            :class="i % 2 == 1 ? 'tr2' : ''"
            v-for="(l, i) of list"
            :key="'jjyb' + i"
          >
            <el-col :span="16">{{ l.gzrq ? l.gzrq : "-" }}</el-col>
            <el-col :span="8"><a class="btn" :href="l.pdf">查看报表</a></el-col>
          </el-row>

          <div style="text-align: center; padding: 30px">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="pages[2]"
              @current-change="changePage"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "App",
  data() {
    return {
      id: null,
      active: 0,
      gmdetail: null,
      list: [],
      pages: [0, 10, 0],
      detail: null,
    };
  },

  methods: {
    changeActive(i) {
      this.active = i;
      this.pages[0] = 0;
      this.pages[2] = 0;
      this.getDetail();
    },
    //类别1月报，2季报，3，年报，4清算，5公告
    getDetail() {
      api
        .gmDetail({
          id: this.$route.params.id,
          fid: this.active,
          pageNum: this.pages[0],
          pageSize: this.pages[1],
        })
        .then((res) => {
          console.log(res);
          if (this.active > 0 && res.code == 200 && res.msg == "查询成功") {
            this.list.splice(0, 100000);
            this.list = this.list.concat(res.rows);
            this.pages[0] = 1;
            this.pages[2] = res.total;
          }
        });
    },
    changePage(e) {
      this.pages[0] = e;
      this.getDetail();
    },
  },
  components: {},
  mounted() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.getDetail();
    }
    this.detail = JSON.parse(sessionStorage.getItem("gmdetail"));
    console.log(this.detail);
  },
};
</script>
<style scoped>
.pro_qishuh1 {
  font-size: 30px;
  padding-bottom: 20px;
}
.nav {
  height: 40px;
  line-height: 40px;
  padding: 10px 0px 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.nav span {
  background: #eeeeee;
  color: #000000;
  padding: 0px 30px;
  display: inline-block;
  margin-right: 20px;
  font-size: 18px;
  transition: 300ms;
  cursor: pointer;
}
.nav .active {
  background: #ec6526;
  color: white;
}
.nav span:hover {
  background: #ec6526;
  color: white;
}
.info {
  line-height: 20px;
  padding: 15px 10px;
  margin: 1px;
  font-size: 14px;
  background: #f4f4f4;
}

.th {
  height: 60px;
  line-height: 60px;
  background: #f4f4f4;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.tr {
  height: 50px;
  line-height: 50px;
  background: #fff;
  text-align: center;
  font-size: 14px;
  border-bottom: 1px solid #f4f4f4;
  color: #666666;
}
.tr2 {
  background: #fafafa;
}
.tr:hover {
  background: #ec6526;
  color: white;
}
.tr .btn {
  background: #ec6526;
  color: #fff;
  padding: 5px 10px;
}
.tr:hover .btn {
  background: #fff;
  color: #ec6526;
  padding: 5px 10px;
}
.page {
  margin: 20px auto;
}

.nocontent {
  text-align: center;
  margin: 30px auto 40px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .th {
    height: 44px;
    line-height: 44px;
    /* background: #f4f4f4; */
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .info {
    line-height: 20px;
    padding: 10px 10px;
    margin: 0;
    border: 2px solid white;
    font-size: 14px;
    /* background: #f4f4f4; */
  }
  .active {
    background: #ec6526;
    color: white;
  }
  .info2 {
    margin-top: 10px;
  }
  .info2 .info {
    line-height: 20px;
    padding: 10px 10px;
    margin: 0;
    border: none;
    font-size: 14px;
    border-bottom: 1px solid #ccc;
  }
}
</style>
